<template>
  <div class="container mx-auto p-4">
    <h1 class="text-2xl font-bold mb-4">Today's Auctions</h1>

    <div v-if="loading" class="text-center">
      <p>Loading...</p>
    </div>

    <div
      v-else-if="error"
      class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
      role="alert"
    >
      <strong class="font-bold">Error!</strong>
      <span class="block sm:inline"> {{ error }}</span>
    </div>

    <template v-else>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        <div
          v-for="auction in auctions"
          :key="auction.id"
          class="bg-white p-6 rounded-lg shadow-md flex flex-col"
        >
          <img
            v-if="auction.picUrl && auction.picUrl.length > 0"
            :src="auction.picUrl[0]"
            :alt="auction.title"
            class="w-full h-56 object-contain mb-4 rounded"
          />
          <h2 class="text-xl font-semibold mb-2">{{ auction.title }}</h2>
          <p class="mb-2">{{ auction.location.nickName }}</p>
          <p class="mb-2">
            Ends at: {{ new Date(auction.utcEndDateTime).toLocaleString() }}
          </p>
          <p class="mb-2">
            Time Left: <CountdownTimer :endDateTime="auction.utcEndDateTime" />
          </p>
          <NuxtLink
            :to="`/auction/${auction.id}`"
            class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 inline-block mt-auto"
          >
            View Auction
          </NuxtLink>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
/**
 * api/auctions response:
 * [
  {
    "id": 401522,
    "auctionNumber": "BRO2401945",
    "itemCount": 50,
    "locationId": 25,
    "utcStartDateTime": "2024-06-28T18:00:00Z",
    "utcEndDateTime": "2024-07-01T12:00:00Z",
    "type": "Online Auction",
    "picUrl": [
      "https://m.media-amazon.com/images/I/71a3F7P53BL.jpg",
      "https://s3.amazonaws.com/lotting-images-prod/bidfta/GRN6464075_1718990833151.jpeg",
      "https://m.media-amazon.com/images/I/61NhaS5UySL._AC_SL1000_.jpg"
    ],
    "title": "Morning Deals To Start Your Day! ",
    "category": "Retail Inventory",
    "binEnabled": false,
    "maoEnabled": false,
    "bidsEnabled": true,
    "status": "live",
    "pastAuction": false,
    "isMnr": false,
    "location": {
      "id": 25,
      "nickName": "Broadwell",
      "address": "8485 Broadwell Road",
      "city": "Cincinnati",
      "state": "OH",
      "zip": "45244",
      "map": "<iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3157.6903247981463!2d-85.89489038468507!3d37.679983779776784!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8868e9a7209bba99%3A0xacfbe941031127c6!2s8485+Broadwell+Rd+%2C+Cincinnati%2C+OH+45244%2C+USA!5e0!3m2!1sen!2sin!4v1532408575391\" width=\"600\" height=\"450\" frameborder=\"0\" style=\"border:0\" allowfullscreen></iframe>",
      "taxRate": 7.8
    },
    "timeRemaining": "21760",
    "endDateText": "2024-07-01",
    "endTimeText": "08:00 AM",
    "startDateText": "2024-06-28",
    "startTimeText": "02:00 PM",
    "lastDayofAuction": true,
    "paidAuction": false,
    "endTimeElapsed": false,
    "pickupDates": [
      "July 2nd, 2024",
      "July 3rd, 2024",
      "July 5th, 2024"
    ],
    "auctionTypeId": 1
  },
 */
import CountdownTimer from "~/components/CountdownTimer.vue";
import { ref, onMounted } from "vue";

const auctions = ref([]);
const loading = ref(true);
const error = ref(null);

onMounted(async () => {
  try {
    auctions.value = await $fetch("/api/auctions");
    if (auctions.value.length === 0) {
      error.value = "No auctions found for today";
    }
  } catch (err) {
    console.error("Error fetching auctions:", err);
    error.value = "Failed to load auction data";
  } finally {
    loading.value = false;
  }
});
</script>
